export const images = {
  // logoOnly: logoOnly,
  reportMenuIcon: '/assets/report-icon.png',
  keywordMenuIcon: '/assets/keyword-icon.png',
  templateMenuIcon: '/assets/template-icon.png',
  communityMenuIcon: '/assets/community-icon.png',
  syncMenuIcon: '/assets/sync-icon.png',
  errorImageIcon: '/assets/error-image.png',
  logo: '/assets/logo.svg',
  instructionImage1: '/assets/instructions/instruction_1.png',
  instructionImage2: '/assets/instructions/instruction_2.png',
  instructionImage3: '/assets/instructions/instruction_3.png',
  featureImage1: '/assets/feature1.png',
  featureImage2: '/assets/feature2.png',
  featureVideo3: '/assets/feature3.webm',
  featureImage4: '/assets/feature4.png',
  kakaotalkImage: '/assets/kakaotalk-logo.png'
};

export const lottie = {
  search: '/assets/searching.json'
}


export const BLUR_URL =
  'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFklEQVR42mN8//HLfwYiAOOoQvoqBABbWyZJf74GZgAAAABJRU5ErkJggg==';
